import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding, inject,
  Input,
  OnInit,
  output,
  Output
} from '@angular/core';

import { BibleQueryResult } from '../bible-search-query/bible-search-query-dialog/bible-search-query-dialog.component';
import { ChapterChangeResult } from '../chapter-button/chapter-button.component';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { SnackBarType } from '../../../../components/snackbar/snackbar-type.enum';
import { StateService } from '../../../../services/state/state.service';
import { SubComponent } from '../../../../components/utils/sub/sub.component';

@Component({
  selector: 'app-bible-actions',
  templateUrl: './bible-actions.component.html',
  styleUrls: ['./bible-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BibleActionsComponent extends SubComponent implements OnInit {
  @Input({ required: true }) protected readonly chapter: number;
  @Input({ required: true }) protected readonly hidePrevButton = false;
  @Input({ required: true }) protected readonly hideNextButton = false;

  @Input({ required: true }) @HostBinding('class.hide-actions') protected readonly hideActions = false;
  @HostBinding('class.hide-panels') hidePanels = false;

  @Input({ required: true }) protected readonly isFavouriteChapter: boolean;

  @Output() protected readonly chapterChange$ = new EventEmitter<ChapterChangeResult>();
  @Output() protected readonly openToolsPanel$ = new EventEmitter<void>();
  @Output() protected readonly copyVerses$ = new EventEmitter<void>();
  @Output() protected readonly openBibleBookDialog$ = new EventEmitter<void>();
  @Output() protected readonly openSelectTranslationsDialog$ = new EventEmitter<void>();
  @Output() private readonly favouriteChapterChange$ = new EventEmitter<boolean>();
  @Output() private readonly changeFontSize$ = new EventEmitter<number>();
  @Output() private readonly fullScreen$ = new EventEmitter<boolean>();
  @Output() private readonly versesInNewLine$ = new EventEmitter<boolean>();

  protected readonly bibleSearchQuery$ = output<BibleQueryResult>();

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly snackbarService = inject(SnackbarService);
  private readonly stateService = inject(StateService);

  protected get showLeftPanel(): boolean { return this.stateService.bibleShowLeftPanel; }
  protected set showLeftPanel(value: boolean) { this.stateService.setBibleShowLeftPanel(value); }

  protected get showRightPanel(): boolean { return this.stateService.bibleShowRightPanel; }
  protected set showRightPanel(value: boolean) { this.stateService.setBibleShowRightPanel(value); }

  protected get fullScreen(): boolean { return this.stateService.fullScreen; }
  protected set fullScreen(value: boolean) {
    this.stateService.setFullScreen(value);
    this.fullScreen$.emit(value);
    this.hidePanels = value;
  }

  protected get versesInNewLine(): boolean { return this.stateService.bibleVersesInNewLine; }
  protected set versesInNewLine(value: boolean) {
    this.versesInNewLine$.emit(value);
    this.stateService.setBibleVersesInNewLine(value);
  }

  protected get bibleFontSize(): number {
    return this.stateService.bibleFontSize;
  }

  ngOnInit(): void {
    this.versesInNewLine$.emit(this.versesInNewLine);
    this.hidePanels = this.stateService.fullScreen;
    this.observeVersesInNewLine();
  }

  favouriteChapterChange(): void {
    this.favouriteChapterChange$.emit(!this.isFavouriteChapter);
    if (this.isFavouriteChapter) {
      this.snackbarService.open('Usunięto rozdział z ulubionych', SnackBarType.REMOVE, 2000)
    }
  }

  protected changeFontSize(): void {
    if (this.bibleFontSize === 4) {
      this.stateService.setBibleFontSize(0);
    } else {
      this.stateService.setBibleFontSize(this.bibleFontSize + 1);
    }
    this.changeFontSize$.emit(this.stateService.bibleFontSize);
  }

  protected bibleSearchQuery($event: BibleQueryResult): void {
    this.bibleSearchQuery$.emit($event);
  }

  private observeVersesInNewLine(): void {
    this.subscription.add(this.stateService.bibleVersesInNewLine$
      .subscribe(value => {
        this.versesInNewLine$.emit(value);
        this.cdr.markForCheck();
      }));
  }
}
